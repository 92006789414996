<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="invoiceModal"
    >
      <CRow>
        <CCol md="12">
          <CForm>
            <CRow>
              <CCol md="8">
                <CSelect
                  label="Factura:"
                  :isValid="checkIfValid('uuid')"
                  :value.sync="$v.form.uuid.$model"
                  :options="invoicesOptions"
                  @change="changeInvoice"
                />
              </CCol>
              <CCol md="4">
                <CInput
                  label="Monto (sin impuestos):"
                  :lazy="false"
                  :isValid="checkIfValid('amount')"
                  :value.sync="$v.form.amount.$model"
                  placeholder=""
                  invalidFeedback="Este campo es requerido y debe ser valor mayor que cero."
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput
                  label="Descripción:"
                  :lazy="false"
                  :isValid="checkIfValid('description')"
                  :value.sync="$v.form.description.$model"
                  placeholder=""
                  invalidFeedback="Este campo es requerido."
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CSelect
                  label="Impuestos:"
                  :isValid="checkIfValid('taxes')"
                  :value.sync="$v.form.taxes.$model"
                  :options="taxesOptions"
                />
              </CCol>
            </CRow>
          </CForm>
        </CCol>
      </CRow>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, minValue, email} from "vuelidate/lib/validators"
import { DatePicker } from 'v-calendar'
import serials_ws from '../../../services/serials';
import units_ws from '../../../services/units';
import fiscal_products_ws from '../../../services/fiscal-products';
import general_ws from '../../../services/general';
import products_ws from '../../../services/products';
import taxes_ws from '../../../services/taxes';
import invoices_ws from '../../../services/invoices';

import moment from 'moment';

const today = new moment();
const today2 = new moment();

export default { 
  name: 'InvoiceModal',
  props: {

  },
  components: {
    'v-date-picker': DatePicker
  },
  data () {
    return {
      invoiceModal: false,
      title: "Agregar Factura", 
      color: "info",
      isEdit: false,
      form: {},
      submitted: false,
      invoices: [],
      invoicesOptions: [],
      taxes: [],
      taxesOptions: [],
      default_unit: false,
      default_tax: false
    }
  },
  mounted: async function() {
    let response1 = await invoices_ws.getUUIDs("XAXX010101000"); 

    if(response1.type == "success"){
      this.invoices = response1.data;

      this.invoicesOptions = await this.$parseSelectOptionsOrdered(this.invoices, "uuid", "folio:uuid");
    }

    let response2 = await taxes_ws.list(); 

    if(response2.type == "success"){
      this.taxes = response2.data;

      this.default_tax = await this.$findDefaultTax(this.taxes);
    }

    this.taxesOptions = await this.$parseSelectOptionsOrdered(this.taxes, "code", "name");
  },
  methods: {
    async changeInvoice () {
      let invoice = await this.$findElementByUUID(this.invoices, this.form.uuid);

      this.form.folio = invoice.folio;

      this.form.description = 'Nota de crédito para la factura '+invoice.folio;
    },
    async addInvoiceModal (rfc, uuids) {
      this.invoiceModal = true;
      this.color = "info";
      this.title = 'Agregar Factura';
      this.isEdit = false;
      this.submitted = false;

      let response1 = await invoices_ws.getUUIDs(rfc, {uuids: uuids}); 

      if(response1.type == "success"){
        this.invoices = response1.data;

        this.invoicesOptions = await this.$parseSelectOptions(this.invoices, "uuid", "folio:uuid");
      }

      this.form = {
        amount: '',
        description: 'Nota de crédito para la factura '+this.invoices[0].folio,
        uuid: this.invoices[0].uuid,
        folio: this.invoices[0].folio,
        taxes: this.default_tax.code
      };
    },
    editInvoiceModal (data) {
      this.invoiceModal = true;
      this.color = "info";
      this.title = 'Editar Factura';
      this.isEdit = true;
      this.submitted = false;

      this.form = {
        amount: data.amount,
        uuid: data.uuid,
        folio: data.folio,
        description: data.description,
        taxes: data.tax_id
      };
    },
    async store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        let taxes = await this.$findTax(this.taxes, this.form.taxes);
        
        if(!this.isEdit){
          this.$emit("addInvoice", this.form, taxes);
        }
        else{
          this.$emit("updateInvoice", this.form, taxes);
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.invoiceModal = false;

      this.submitted = false
      this.$v.$reset()
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      amount: {
        required, 
        minValue: minValue(0.01)
      },
      uuid: {
        required
      },
      folio: {},
      description: {
        required
      },
      taxes: {
        required
      },
    }
  },
}
</script>