<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CreditNotesView
          :id="id"
          :is_new="id > 0 ? false : true"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CreditNotesView from '../components/credit-notes/CreditNotesView.vue'
 
export default {
  name: 'CreditNote',
  components: { CreditNotesView },
  data: function () {
		return {
      id: 0
    }
  },
  mounted: async function() {
      this.id = await this.$route.params.id;
  },
  methods: {

  }
}
</script>
