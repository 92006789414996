<template>
  <div>
    <CRow>
      <CCol sm="4">
       <CCard class="invoice-card">
        <CCardHeader color="info">
          Emisor
          <div class="card-header-actions">
            
          </div>
        </CCardHeader>
        <CCardBody>
          <b>{{invoice.emisor.rfc}}</b>
          <br />
          {{invoice.emisor.name}}
          <br />
          <br />
          {{invoice.emisor.regimen.code}} - {{invoice.emisor.regimen.name}}
        </CCardBody>
      </CCard>
      </CCol>
      <CCol sm="4">
       <CCard class="invoice-card">
        <CCardHeader color="info">
          <slot name="header">
            Receptor
            <div class="card-header-actions" v-if="is_new">
              <CButton class="btn btn-light" size="sm" @click="receptorModal(invoice.receptor)">Editar</CButton>
            </div>
          </slot>
        </CCardHeader>
        <CCardBody>
          <b>{{invoice.receptor.rfc}}</b>
          <br />
          {{invoice.receptor.name}} <span v-if="invoice.receptor.rfc == 'XEXX010101000' && invoice.receptor.identifier != undefined && invoice.receptor.identifier != ''">[Reg. Id: {{invoice.receptor.identifier}}]</span>
          <br />
          <br />
          {{invoice.receptor.uso_cfdi.code}} - {{invoice.receptor.uso_cfdi.name}}
        </CCardBody>
        <ReceptorModal ref="receptorModal" type="E" @updateReceptor="updateReceptor"></ReceptorModal>
      </CCard>
      </CCol>
      <CCol sm="4">
       <CCard class="invoice-card">
        <CCardHeader color="info">
          <slot name="header">
            Información de la Nota de Crédito
            <div class="card-header-actions" v-if="is_new">
              <CButton class="btn btn-light" size="sm" @click="informationModal(invoice.information)">Editar</CButton>
            </div>
          </slot>
        </CCardHeader>
        <CCardBody>
          <div class="card-header-actions text-right">
              <b>Fecha: </b>{{invoice.information.date | date}}
              <br /><br /><br />
              <b>Moneda: </b>{{invoice.information.currency.code}} <span v-show="invoice.information.currency.code != 'MXN'">({{invoice.information.currency.exchange_rate}})</span>
          </div>
          <b>Folio: </b>{{invoice.information.serial}}{{invoice.information.folio}}
          <br />
          <b>Folio Fiscal: </b>{{invoice.information.uuid}}
          <br />
          <b>Forma de Pago: </b>{{invoice.information.payment_form.code}} - {{invoice.information.payment_form.name}}
          <br />
          <b>Método de Pago: </b>{{invoice.information.payment_method.code}} - {{invoice.information.payment_method.name}}
        </CCardBody>
        <InformationModal ref="informationModal" type="expense" @updateInformation="updateInformation"></InformationModal>
      </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
       <CCard class="invoice-concepts">
        <CCardHeader>
          <slot name="header">
            Conceptos
            <div class="card-header-actions">
              <!-- <CButton v-if="is_new" class="btn btn-outline-success" size="sm" v-show="!has_relations" @click="enableRelations()">CFDI Relacionados</CButton> -->
              <CButton v-if="is_new" class="btn btn-outline-info" size="sm" @click="addInvoiceModal(invoice.receptor.rfc)">Agregar Factura</CButton>
              <span v-if="!is_new && invoice.canceled">CANCELADA</span>
            </div>
          </slot>
        </CCardHeader>
        <CCardBody>
          <ConceptsTable
            :items="concepts"
            :subtotal="subtotal"
            :transfered_taxes="transfered_taxes"
            :withheld_taxes="withheld_taxes"
            :total="total"
            :letters="letters"
            :is_new="is_new"
            hover
            striped
            border
            small
            fixed
            caption="Conceptos"
            @refresh="refreshConcepts"
            @deleteConcept="deleteConcept"
          />
          <InvoiceModal ref="invoiceModal" @addInvoice="addInvoice"></InvoiceModal>
        </CCardBody>
      </CCard>
      </CCol>
    </CRow>
    <CRow v-if="has_relations">
      <CCol sm="12">
       <CCard>
        <CCardHeader>
          <slot name="header">
            CFDIs Relacionados
            <div class="card-header-actions" v-if="is_new">
              <CButton class="btn btn-outline-dark" size="sm" @click="disableRelations()" v-show="has_relations && relations.length == 0">Cerrar</CButton>
              <CButton class="btn btn-outline-info" size="sm" @click="addRelationModal(invoice.receptor.rfc, relations)">Agregar CFDI</CButton>
            </div>
          </slot>
        </CCardHeader>
        <CCardBody>
          <RelationsTable
            :items="relations"
            :is_new="is_new"
            :relation_type="relation_type"
            hover
            striped
            border
            small
            fixed
            caption="CFDIs Relacionados"
            @refresh="refreshRelations"
            @deleteRelation="deleteRelation"
          />

          <RelationModal ref="relationModal" type="E" :relation_type="relation_type" @addRelation="addRelation" :relationDisabled="relationDisabled"></RelationModal>
        </CCardBody>
      </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12" v-if="has_access">
        <CCard>
          <CCardBody class="text-left">
            <CButton class="btn btn-outline-danger" size="sm" @click="cancelInvoice()" v-if="!is_new && !invoice.canceled">Cancelar</CButton>
            <!-- <CButton class="btn btn-outline-danger" size="sm" @click="downloadCancelationPDF()" v-if="!is_new && invoice.canceled">Acuse de Cancelación</CButton> -->
            <CButton class="btn btn-outline-dark" size="sm" @click="clearInvoice()" v-if="is_new">Limpiar</CButton>
            <div class="card-header-actions">
              <CButton color="success" size="sm" @click="downloadXML()" v-if="!is_new">XML</CButton>
              <CButton color="danger" size="sm" @click="downloadPDF()" v-if="!is_new">PDF</CButton>
              <CButton color="danger" size="sm" @click="previewPDF()" v-if="is_new">Vista Previa</CButton>
              <CButton color="info" size="sm" @click="sendInvoiceModal()" v-if="!is_new">Enviar por Correo</CButton>
              <CButton color="info" size="sm" @click="storeInvoice()" v-if="is_new">Emitir Nota de Crédito</CButton>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CancelationModal ref="cancelModal" @delete="cancelProcess"></CancelationModal>
    <EmailModal ref="emailModal" @send="sendInvoice"></EmailModal>
  </div>
</template>

<script>
import ConceptsTable from '../credit-notes/ConceptsTable.vue'
import RelationsTable from '../invoices/RelationsTable.vue'
import ReceptorModal from '../invoices/modals/ReceptorModal.vue'
import InformationModal from '../invoices/modals/InformationModal.vue'
import EmailModal from '../invoices/modals/EmailModal.vue'
import CancelationModal from '../invoices/modals/CancelationModal.vue'
import InvoiceModal from '../credit-notes/modals/InvoiceModal.vue'
import RelationModal from '../invoices/modals/RelationModal.vue'
import ws from '../../services/credit-notes';
import serials_ws from '../../services/serials';
import products_ws from '../../services/products';
import account_ws from '../../services/account';
import store from '../../store'

import moment from 'moment';

moment.tz.setDefault('America/Mexico_city')

let today = new moment();
today = new moment(today.format("YYYY-MM-DD HH:m:ss"));

export default {
  name: 'CreditNotesView',
  components: { ConceptsTable, RelationsTable, ReceptorModal, InformationModal, InvoiceModal, RelationModal, EmailModal, CancelationModal },
  props: {
    
  },
  data() {
    return {
      id: 0,
      is_new: true,
      blocked: false,
      has_relations: false,
      products: [],
      invoice: {
        id: 0,
        emisor: {
          rfc: '{rfc}',
          name: '{name}',
          regimen: {
            code: '{regimen_code}',
            name: '{regimen_name}'
          }
        },
        receptor: {
          customer_id: 0,
          rfc: 'XAXX010101000',
          name: 'PÚBLICO EN GENERAL',
          regimen: {
            code: '616',
            name: 'SIN OBLIGACIONES FISCALES'
          },
          uso_cfdi: {
            code: 'S01',
            name: 'SIN EFECTOS FISCALES'
          },
          identifier: '',
          street: '',
          exterior: '',
          interior: '',
          colony: '',
          municipality: '',
          state: 'JAL',
          country: 'MEX',
          zipcode: '46600'
        },
        information: {
          document_type: 'E',
          serial: 'F',
          folio: 0,
          uuid: '',
          date: today,
          payment_form: {
            code: '03',
            name: 'Transferencia'
          },
          payment_method: {
            code: 'PUE',
            name: 'Pago en una sola exhibición'
          },
          currency: {
            code: 'MXN',
            name: 'Peso Mexicano'
          },
          exchange_rate: 1.0000
        },
        canceled: 0
      },
      concepts: [],
      relations: [],
      relationDisabled: false,
      relation_type: "04",
      subtotal: 0,
      transfered_taxes: 0,
      withheld_taxes: 0,
      total: 0,
      letters: '',
      toasts: []
    }
  },
  mounted: async function() {
      this.loading();
      this.id = await this.$route.params.id;

      if(this.id > 0){
        this.is_new = false;
      }

      if(!this.is_new && this.relations.length > 0){
        this.has_relations = true;
      }

      let response1 = await account_ws.getFiscalInfo(); 

      if(response1.type == "success"){
        this.invoice.emisor.rfc = response1.data.rfc.toUpperCase();
        this.invoice.emisor.name = response1.data.fiscal_name.toUpperCase();
        this.invoice.emisor.regimen = response1.data.regimen;
      }

      let response2 = await serials_ws.getDefault("expense"); 

      if(response2.type == "success"){
        let serial = response2.data;

        this.invoice.information.serial = serial.serial;
        this.invoice.information.folio = serial.current;
        this.invoice.information.serial_id = serial.id;
      }

      if(this.id > 0){
        this.showInvoice();
      }
      else{
        //Si los timbres estan vencidos o agotados => Redirecciona a Subscripción
        if(store.state.deadline_days <= 0 || store.state.stamps <= 0){
          //window.location.href = "/#/account/subscription"; return;
        }
        
        this.loaded();
      }

  },
  methods: {
    enableRelations () {
      this.has_relations = true;
    },
    disableRelations () {
      this.has_relations = false;
    },
    receptorModal (data) {
      this.$refs.receptorModal.openModal(data);
    },
    informationModal (data) {
      this.$refs.informationModal.openModal(data);
    },
    addInvoiceModal (rfc) {
      let uuids = [];
      this.concepts.forEach(item => {
          let uuid = item.uuid;

          uuids.push(uuid);
      });

      this.$refs.invoiceModal.addInvoiceModal(rfc, uuids);
    },
    addRelationModal (rfc, data) {
      this.$refs.relationModal.addRelationModal(rfc, data);
    },
    async refreshConcepts (items){
      this.updateTotals();
    },
    async refreshRelations (items){
      this.relations = await items;
    },
    async showInvoice (){
      this.loading();
      let response = await ws.show(this.id);

      this.invoice.id = this.id;

      if(response.type == "success"){
        let data = response.data;

        this.invoice.uuid = response.data.uuid;
        this.invoice.canceled = response.data.canceled;

        this.invoice.emisor.rfc = data.emisor.rfc.toUpperCase();
        this.invoice.emisor.name = data.emisor.nombre.toUpperCase();
        this.invoice.emisor.regimen.code = data.emisor.regimen.code;
        this.invoice.emisor.regimen.name = data.emisor.regimen.name;

        this.invoice.receptor.rfc = data.receptor.rfc.toUpperCase();
        this.invoice.receptor.name = data.receptor.nombre.toUpperCase();
        this.invoice.receptor.regimen.code = data.receptor.regimen.code;
        this.invoice.receptor.regimen.name = data.receptor.regimen.name;
        this.invoice.email = response.data.email;

        this.invoice.receptor.uso_cfdi = {
          code : data.uso_cfdi.code,
          name: data.uso_cfdi.name
        }

        this.invoice.information.serial = data.cfdi.serie;
        this.invoice.information.folio = data.cfdi.folio;
        this.invoice.information.uuid = data.uuid;
        
        this.invoice.information.currency = {
          code: data.currency,
          exchange_rate: data.exchange_rate
        };

        this.invoice.information.date = data.date;

        this.subtotal = 0;
        this.transfered_taxes = 0;
        this.withheld_taxes = 0;
        this.total = 0;

        data.conceptos.forEach(item => {
            let concept = {
              sat_code: item.clave_prod_serv, 
              quantity: item.cantidad, 
              unit: {
                unit_code: item.clave_unidad, 
                unit_name: item.unidad
              }, 
              description: item.descripcion, 
              price: item.valor_unitario, 
              tax_id: 0,
              taxes: item.taxes, 
              total: item.importe,
              folio: item.serial+""+item.folio,
              uuid: item.uuid
            };

            this.subtotal += concept.total * 1;

            item.taxes.forEach(tax => {
              if(tax.type == "TRASLADO"){
                this.transfered_taxes += concept.total * tax.rate;
              }
              else{
                this.withheld_taxes += concept.total * tax.rate;
              }
            });

            this.concepts.push(concept); 
        });

        this.total = this.subtotal + this.transfered_taxes - this.withheld_taxes;
        this.letters = data.total_letra;

        this.loaded();
      }
    },
    async updateReceptor(data) {
      this.invoice.receptor.customer_id = data.customer_id;
      this.invoice.receptor.rfc = data.rfc;
      this.invoice.receptor.name = data.name;
      
      this.invoice.receptor.uso_cfdi.code = data.uso_cfdi_code;
      this.invoice.receptor.uso_cfdi.name = data.uso_cfdi_name;

      this.invoice.receptor.regimen.code = data.regimen_code;
      this.invoice.receptor.regimen.name = data.regimen_name;

      this.invoice.receptor.identifier = data.identifier;

      this.invoice.receptor.street = data.street;
      this.invoice.receptor.exterior = data.exterior;
      this.invoice.receptor.interior = data.interior;
      this.invoice.receptor.colony = data.colony;
      this.invoice.receptor.municipality = data.municipality;
      this.invoice.receptor.state = data.state;
      this.invoice.receptor.country = data.country;
      this.invoice.receptor.zipcode = data.zipcode;

      let concept = {};

      this.concepts.push(concept);

      this.clearInvoice();
    },
    async updateInformation(data) {
      this.invoice.information.serial = data.serial;
      this.invoice.information.folio = data.folio;
      this.invoice.information.serial_id = data.serial_id;
      
      this.invoice.information.payment_form.code = data.payment_form_code;
      this.invoice.information.payment_form.name = data.payment_form_name;
      this.invoice.information.payment_method.code = data.payment_method_code;
      this.invoice.information.payment_method.name = data.payment_method_name;

      this.invoice.information.currency.code = data.currency_code;
      this.invoice.information.currency.name = data.currency_name;
      this.invoice.information.currency.exchange_rate = data.exchange_rate;

      let date = moment(data.date);

      this.invoice.information.date = date;
    },
    async addInvoice(data, taxes) {
      data.amount = data.amount.replaceAll(',', '');

      let concept = {
        quantity: 1,
        sat_code: "84111506",
        unit: {
          code: "ACT", 
          name: "Actividad"
        }, 
        description: data.description, 
        price: data.amount, 
        tax_id: taxes.id,
        total: data.amount,
        import: data.amount,
        folio: data.folio, 
        uuid: data.uuid, 
        amount: data.amount,
        taxes: taxes.taxes,
        transfered_taxes: 0,
        withheld_taxes: 0
      };

      this.concepts.push(concept);

      this.updateTotals();
    },
    async addRelation(data) {
      
      this.relations.push(data.uuid);
      this.relation_type = data.relation_type;

      this.relationDisabled = true;
    },
    deleteConcept(index) {
      this.concepts.splice(index, 1);

      this.updateTotals();
    },
    deleteRelation(index) {
      this.relations.splice(index, 1);

      if(this.relations.length == 0){
        this.relationDisabled = false;
      }
    },
    clearInvoice() {
      this.concepts = [];

      this.updateTotals();
    },
    updateTotals(){
      this.subtotal = 0;
      this.transfered_taxes = 0;
      this.withheld_taxes = 0;
      this.total = 0;

      this.concepts.forEach(item => {
          this.subtotal += item.amount * 1;

          item.transfered_taxes = 0;
          item.withheld_taxes = 0;

          item.taxes.forEach(tax => {
            if(tax.type == "TRASLADO"){
              item.transfered_taxes += item.amount * tax.rate;
              this.transfered_taxes += item.amount * tax.rate;
            }
            else{
              item.withheld_taxes += item.amount * tax.rate;
              this.withheld_taxes += item.amount * tax.rate;
            }
          });
      });

      this.total = this.subtotal + this.transfered_taxes - this.withheld_taxes;
      this.letters = ""; 
    },
    async storeInvoice() {
      if(this.blocked){
        return;
      }

      this.blocked = true;

      this.loading();

      this.relations = [];
      this.concepts.forEach(item => {
          this.relations.push({uuid: item.uuid});
      });

      let exchange_rate = this.invoice.information.exchange_rate;

      if(this.invoice.information.currency.exchange_rate !== undefined){
        exchange_rate = this.invoice.information.currency.exchange_rate;
      }
      else{
        if(exchange_rate === undefined){
          exchange_rate = 1.000;
        }
      }
      
      let data = {
        customer_id: this.invoice.receptor.customer_id,
        receptor: {
          rfc: this.invoice.receptor.rfc,
          name: this.invoice.receptor.name,
          zipcode: this.invoice.receptor.zipcode,
          regimen: this.invoice.receptor.regimen.code,
          identifier: this.invoice.receptor.identifier,
          street: this.invoice.receptor.street,
          exterior: this.invoice.receptor.exterior,
          interior: this.invoice.receptor.interior,
          colony: this.invoice.receptor.colony,
          municipality: this.invoice.receptor.municipality,
          state: this.invoice.receptor.state,
          country: this.invoice.receptor.country
        },
        uso_cfdi: this.invoice.receptor.uso_cfdi.code,
        version: 4.0,
        date: this.invoice.information.date.format("YYYY-MM-DD HH:m:ss"),
        serial_id: this.invoice.information.serial_id,
        currency: this.invoice.information.currency,
        exchange_rate: exchange_rate,
        payment_method: this.invoice.information.payment_method,
        payment_form: this.invoice.information.payment_form,
        concepts: this.concepts,
        relations: this.relations,
        relation_type: this.has_relations ? this.relation_type : "04" 
      }

      let response = await ws.store(data);

      if(response.type == "success"){
        this.$emit("refresh", response.data );

        window.location.href = "/#/credit-notes/"+response.data.id+"/view";
      }
      else{
        this.loaded();
      }

      this.showToast(response.type, response.message);
      this.blocked = false;
    },
    async sendInvoiceModal() {
      this.$refs.emailModal.show(this.invoice, 'E');
    },
    async sendInvoice(invoice, data) {
      this.loading();
      let response = await ws.send(invoice.id, data);

      this.showToast(response.type, response.message);
      this.loaded();
    },
    async cancelInvoice() {
      this.$refs.cancelModal.show("Eliminar CFDI Relacionado", this.invoice);
    },
    async cancelProcess(id, data) {
      this.loading();
      let response = await ws.cancel(id, data);

      if(response.type == "success"){
        this.clearInvoice();
        this.showInvoice();
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    async downloadXML(){
      ws.downloadXML(this.id);
    },
    async downloadPDF(){
      ws.downloadPDF(this.id);
    },
    async previewPDF(){
      this.relations = [];
      this.concepts.forEach(item => {
          this.relations.push({uuid: item.uuid});
      });

      let data = {
        customer_id: this.invoice.receptor.customer_id,
        receptor: {
          rfc: this.invoice.receptor.rfc,
          name: this.invoice.receptor.name,
          zipcode: this.invoice.receptor.zipcode,
          regimen: this.invoice.receptor.regimen.code,
          street: this.invoice.receptor.street,
          exterior: this.invoice.receptor.exterior,
          interior: this.invoice.receptor.interior,
          colony: this.invoice.receptor.colony,
          municipality: this.invoice.receptor.municipality,
          state: this.invoice.receptor.state
        },
        uso_cfdi: this.invoice.receptor.uso_cfdi.code,
        version: 4.0,
        date: this.invoice.information.date.format("YYYY-MM-DD HH:m:ss"),
        serial_id: this.invoice.information.serial_id,
        currency: this.invoice.information.currency,
        exchange_rate: this.invoice.information.exchange_rate,
        payment_method: this.invoice.information.payment_method,
        payment_form: this.invoice.information.payment_form,
        concepts: this.concepts,
        relations: this.relations,
        relation_type: "01"
      }

      let response = await ws.preview(data);

      if(response.type != "success"){
        this.showToast(response.type, response.message);
      }
    },
    async downloadCancelationPDF(){
      ws.downloadCancelationPDF(this.id);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {
    has_access () {
      return store.state.stamps <= 0 || store.state.deadline_days <= 0 ? false : true;
    },
  }
}
</script>
