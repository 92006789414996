<template>
  <div>
    <CDataTable
      :hover="hover"
      :striped="striped"
      :border="border"
      :small="small"
      :fixed="fixed"
      :items="items"
      :fields="is_new ? fields_with_actions : fields"
      :items-per-page="small ? 10 : 5"
      :dark="dark"
      pagination
      addTableClasses="no-margin"
    >
      <template #folio="{item}"><td class="w100 text-center">{{ item.folio }}</td></template>
      <template #uuid="{item}"><td class="w300 text-center">{{ item.uuid }}</td></template>
      <template #taxes="{item}">
      <td class="w200">
        <div v-for="tax in item.taxes">
          <span style="float: right;">$ {{(item.quantity * item.price * tax.rate) | currency}}</span>
          {{ tax.name }}{{ tax.type == "RETENIDO" || tax.type == "RETENCION" ? " RET" : "" }} {{ tax.rate * 100 | decimal }}%
        </div>
      </td>
      </template>
      <template #amount="{item}"><td class="w100 text-right">$ {{ item.total | currency }}</td></template>
      <template #actions="{item, index}">
        <td class="table_actions b2" v-if="is_new">
          <CButton color="warning" size="sm" @click="updateModal(item)"><CIcon name="cil-pencil"/></CButton>
          <CButton color="danger" size="sm" @click="deleteModal(item, index)"><CIcon name="cil-trash"/></CButton>
        </td>
      </template>
    </CDataTable>
    <CDataTable
      addTableClasses="no-margin"
      :header="false"
      :hover="hover"
      :striped="striped"
      :border="border"
      :small="small"
      :fixed="fixed"
      :fields="totals_fields"
      :items="totals_items"
    >
      <template #labels="{item}"><th class="w200 text-right" style="text-align: right !important;">{{ item.labels }}</th></template>
      <template #totals="{item, index}">
        <td class="w100 text-right">
          $ {{ (index == 0 ? subtotal : (index == 1 ? transfered_taxes : (index == 2 ? withheld_taxes : total))) | currency }}
        </td>
      </template>
      <template #letters="{item, index}"><td rowspan="4" class="text-center" v-if="index == 0">{{letters}}</td></template>
      <template #actions="{item, index}">
        <td class="table_actions b2" v-if="is_new"></td>
      </template>
    </CDataTable>
    <InvoiceModal ref="invoiceModal" @updateInvoice="updateProcess"></InvoiceModal>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
    
  </div>
</template>

<script>
import InvoiceModal from './modals/InvoiceModal.vue'
import DeleteModal from '../global/DeleteModal.vue'
import store from '../../store'

export default {
  name: 'ConceptsTable',
  components: { InvoiceModal, DeleteModal },
  props: {
    is_new: true,
    items: Array,
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    letters: {
      type: String,
      default: ''
    },
    subtotal: 0,
    transfered_taxes: 0,
    withheld_taxes: 0,
    total: 0
  },
  data() {
    return {
      fields: [
        {key: 'folio', label: 'Folio'},
        {key: 'uuid', label: 'UUID'},
        {key: 'description', label: 'Concepto'},
        {key: 'amount', label: 'Monto'},
      ],
      fields_with_actions: [
        {key: 'folio', label: 'Folio'},
        {key: 'uuid', label: 'UUID'},
        {key: 'description', label: 'Concepto'},
        {key: 'amount', label: 'Monto'},
        {key: 'actions', label: 'Acciones', class: 'actions' },
      ],
      toasts: [],
      item: false,
      totals_items: [
        {letters: '', labels: 'Subtotal', totals: '0'},
        {letters: '', labels: 'Impuestos Trasladados', totals: '0'},
        {letters: '', labels: 'Impuestos Retenidos', totals: '0'},
        {letters: '', labels: 'Total', totals: '0'}
      ],
      totals_fields: [
        {key: 'letters', label: ''},
        {key: 'labels', label: ''},
        {key: 'totals', label: ''},
        {key: 'actions', label: ''}
      ]
    }
  },
  mounted: async function() {
    
  },
  methods: {
    updateModal (item) {
      this.$refs.invoiceModal.editInvoiceModal(item);
      this.item = item;
    },
    deleteModal (item, index) {
      this.$refs.deleteModal.show("Eliminar Concepto", index, item.description);
      this.item = item;
    },
    async updateProcess(data, taxes) {
      data.amount = data.amount.replaceAll(',', '');

      this.item.quantity = 1;
      this.item.sat_code = "84111506";
      this.item.unit = {
          code: "ACT", 
          name: "Actividad"
        };
      this.item.description = data.description;
      this.item.price = data.amount;
      this.item.tax_id = taxes.id;
      this.item.taxes = taxes.taxes;
      
      this.item.import = data.amount;
      this.item.total = this.item.import;
      this.item.folio = data.folio;
      this.item.uuid = data.uuid;
      this.item.amount = data.amount;

      this.$emit("refresh");
    },
    async deleteProcess(index) {
      this.$emit("deleteConcept", index);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
  }
}
</script>
